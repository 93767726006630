<template>
  <div>
    <bread-cum v-bind:breadcrumbItems="['โปรโมชั่น', 'การรับโปรโมชั่น']" />
    <user-promotion></user-promotion>
  </div>
</template>

<script>
import UserPromotion from "@/components/UserPromotion.vue"
export default {
  components: { UserPromotion },
}
</script>