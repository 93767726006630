<template>
  <div>
    <loading v-if="isLoading" />
    <div v-if="!isLoading">
      <CDataTable
        :items="items"
        :fields="fields"
        :column-filter="{ external: true }"
        :items-per-page-select="{ label: 'จำนวนแถว' }"
        :table-filter-value.sync="tableFilterValue"
        :column-filter-value.sync="columnFilterValue"
        :sorter-value.sync="sorterValue"
        :items-per-page.sync="itemsPerPage"
        :loading="tableLoading"
        :noItemsView="{
          noResults: 'ไม่พบข้อมูล',
          noItems: 'ไม่พบข้อมูล',
        }"
        v-on:pagination-change="paginationChange"
        v-on:update:column-filter-value="columnFilter"
        hover
        sorter
        dark
        border
        size="sm"
        addTableClasses="w-100"
      >
        <template #userId="{ item }">
          <td>
            <router-link :to="`/members/${item.userId}`">{{
              item.userId
            }}</router-link>
          </td>
        </template>
        <template #action="{ item }">
          <td>
            <button
              v-if="item.status == 'PENDING' && user.levelNumber >= 9"
              @click="cancelPromotion(item.id)"
              class="btn btn-sm btn-outline-danger"
            >
              ยกเลิกโปรโมชัั่น
            </button>
          </td>
        </template>
      </CDataTable>
      <CPagination
        :active-page="activePage"
        :double-arrows="false"
        :pages="totalPage"
        :dots="false"
        v-on:update:activePage="updateActivePage"
      />
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      fields: [
        {
          key: "id",
          label: "#ID",
          _style: "width: 1%",
          filter: true,
          sorter: false,
        },
        {
          key: "userId",
          label: "#สมาชิก",
          sorter: false,
          filter: this.$route.params.userId ? false : true,
        },
        {
          key: "promotionId",
          label: "#โปรโมชั่น",
          sorter: false,
        },
        {
          key: "promotionName",
          label: "ชื่อโปรโมชั่น",
          sorter: false,
          filter: false,
        },
        {
          key: "userTransferRequestId",
          label: "#แจ้งฝากถอน",
          sorter: false,
          filter: false,
        },
        {
          key: "bonusAmount",
          label: "เครดิตโบนัส",
          sorter: false,
          filter: false,
        },
        {
          key: "targetTurnoverAmount",
          label: "เทิร์นโอเวอร์",
          sorter: false,
          filter: false,
        },
        {
          key: "status",
          label: "สถานะ",
          sorter: false,
          filter: false,
        },
        {
          key: "createdAt",
          label: "เวลาสร้าง",
          sorter: false,
          filter: false,
        },
        {
          key: "updatedAt",
          label: "อัพเดตล่าสุด",
          sorter: false,
          filter: false,
        },
        {
          key: "action",
          label: "",
          sorter: false,
          filter: false,
        },
      ],
      items: [],
      isLoading: true,
      tableFilterValue: "",
      columnFilterValue: {
        fromUserId: null,
        toUserId: null,
      },
      sorterValue: {},
      itemsPerPage: 10,
      totalPage: 5,
      activePage: 1,
      tableLoading: true,
    }
  },
  computed: {
    user() {
      return this.$store.state.auth.user.userData
    },
  },
  methods: {
    async loadData() {
      this.tableLoading = true

      if (this.$route.params.userId) {
        this.columnFilterValue.userId = this.$route.params.userId
      }

      try {
        const { data } = await this.axios({
          method: "get",
          url: "promotion/user-promotion",
          params: {
            ...this.columnFilterValue,
            page: this.activePage,
            pageSize: this.itemsPerPage,
          },
        })

        data.data?.forEach((item) => {
          item.createdAt = this.$date(item.createdAt).format(
            "DD/MM/YYYY HH:mm:ss"
          )
          item.updatedAt = this.$date(item.updatedAt).format(
            "DD/MM/YYYY HH:mm:ss"
          )
          item.bonusAmount = this.util.formatMoney(item.bonusAmount)
          item.targetTurnoverAmount = this.util.formatMoney(
            item.targetTurnoverAmount
          )
        })

        this.items = data.data
        this.isLoading = false
        this.tableLoading = false
      } catch (e) {
        console.log(e)
        this.$swal({
          icon: "error",
          title: "เกิดข้อผิดพลาด",
        })
      }
    },
    paginationChange(val) {
      this.itemsPerPage = val
      this.loadData()
    },
    updateActivePage(val) {
      if (
        parseInt(val) == parseInt(this.totalPage) &&
        this.items &&
        this.items.length != 0
      ) {
        this.totalPage++
      }

      this.activePage = val
      this.loadData()
    },
    columnFilter() {
      if (this.timer) {
        clearTimeout(this.timer)
        this.timer = null
      }
      this.timer = setTimeout(() => {
        this.loadData()
      }, 500)
    },
    async cancelPromotion(id) {
      let confirm = await this.swal.confirm(
        "ยกเลิกโปรโมชั่น",
        "ท่านต้องการยกเลิกโปรโมชั่นใช่หรือไม่"
      )
      if (!confirm) {
        return
      }

      this.swal.processing()

      try {
        await this.axios({
          method: "post",
          url: "promotion/cancel/" + id,
        })

        await this.$swal({
          icon: "success",
          title: "สำเร็จ",
        })

        this.loadData()
      } catch (e) {
        this.swal.catchError(e)
      }
    },
  },
  mounted() {
    this.loadData()
  },
}
</script>